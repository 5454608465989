import "./App.css";

import background from "./simone-head.jpeg";
function App() {
  return (
    <div>
      <div
        style={{
          background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <section className="text-gray-600 font-rocky ">
          <div className="container mx-auto flex h-screen flex-col py-24 justify-center items-center">
            <div className="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
              <h1 className="md:text-8xl text-6xl mb-4 font-thin text-white tracking-widest">
                trillissimo
              </h1>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
